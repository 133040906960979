<template>
  <div class="exam" v-loading="loading">
    <el-tabs v-model="current">
      <!-- <el-tab-pane label="首页" name="first"></el-tab-pane> -->
      <el-tab-pane label="教学综合能力评测" name="second">
        <el-card>
          <h1 class="text-center headline">教学综合能力评测</h1>
          <el-row :gutter="30">
            <el-col :span="12" class="text-left">
              <!-- <img src="../assets/images/exam/img2.png" alt srcset /> -->
              <div class="paperBrief">
                <h4>模拟练习说明：</h4>
                <p>（1）付费完成后，系统即为学员开通模拟练习及正式考试权限；</p>
                <p>
                  （2）通过模拟练习，结合评测大纲，用户可以进行网培师综合能力相关知识的学习；
                </p>
                <p>
                  （3）模拟练习为随机抽取的200道题，模拟练习为120分钟、可重复参加练习；
                </p>
                <p>（4）模拟练习不记录成绩。</p>
              </div>
              <el-button
                class="testbtn"
                type="danger"
                @click="isPay('https://ks.netban.cn/entrance/725', 725)"
                >开始模拟练习</el-button
              >
            </el-col>
            <el-col :span="12" class="text-left">
              <!-- <img src="../assets/images/exam/img1.png" alt srcset /> -->
              <div class="paperBrief paperBrief-right">
                <h4>正式考试说明：</h4>
                <p>（1）本项评测要求考生采用在线答题的方式进行，共200道题；</p>
                <p>
                  （2）本项考试试题分为（单选题、多选题、判断题）三种题型，请注意审题，考试时限为120分钟；
                </p>
                <p>（3）考试成绩正确率达到60%则判定本模块评测通过；</p>
                <p>（4）考试时间到后，考生应停止答题，系统将自动收卷。</p>
                <p>
                  （5）正式考试每个月都会定期开放，下次正式考试开放时间为每月周六的9：00~21：00点，请考生注意时间安排。
                </p>
              </div>
              <el-button
                class="right-button testbtn"
                type="danger"
                @click="isPay('https://ks.netban.cn/entrance/627', 627)"
                >开始正式考试</el-button
              >
            </el-col>
          </el-row>
          <p class="cont">
            友情提示：
            <br />（1）正式考试开始时，系统会进行身份信息认证，学员的身份信息将和考取的网培师证书绑定，因此请正确填写您的身份证信息，信息一经确认将无法修改，因身份信息错误造成的后果，本平台不承担该责任；
            <br />（2）网培师智能监考平台在考试过程中会通过远程视频监控不定时地进行身份信息识别；
            <br />（3）考生考试期间请保持面部一直处于摄像头有效区域，如考试期间被抓拍到离开摄像头有效区域，则本次考试视为无效！
            <br />（4）考生在参加评测期间应该遵守考试纪律，独立完成评测内容；考试时不得向他人交流，也不能通过搜索引擎、书本等工具查阅相关内容；
            <br />（5）违反考试规定者一经发现，则作废本次所有考试并视为本次所有考试不及格！
          </p>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="网培政策规范评测" name="third">
        <el-card>
          <h1 class="text-center headline">网培政策规范评测</h1>
          <div class="text-center download">
            <el-link @click="downloadShow">政策法规资料查看与下载</el-link>
          </div>

          <el-row :gutter="30">
            <el-col :span="12" class="text-left">
              <!-- <img src="../assets/images/exam/img2.png" alt srcset /> -->
              <div class="paperBrief">
                <h4>模拟练习说明：</h4>
                <p>（1）付费完成后，系统即为学员开通模拟练习及正式考试权限；</p>
                <p>
                  （2）通过模拟练习，结合评测大纲，用户可以进行网培师政策规范能力相关政策知识的学习；
                </p>
                <p>
                  （3）为响应国内相关政策，考生应当熟练掌握网培政策规范知识；
                </p>
                <p>
                  （4）模拟练习为顺序练习（系统将全部试题顺序展示，正式考试为100道），模拟练习不限时间、可重复参加练习；
                </p>
                <p>（5）模拟练习不记录成绩。</p>
              </div>
              <el-button
                class="testbtn"
                type="danger"
                @click="isPay('https://ks.netban.cn/entrance/714', 714)"
                >开始模拟练习</el-button
              >
            </el-col>
            <el-col :span="12" class="text-left">
              <!-- <img src="../assets/images/exam/img1.png" alt srcset /> -->
              <div class="paperBrief paperBrief-right">
                <h4>正式考试说明：</h4>
                <p>（1）本项评测要求考生采用在线答题的方式进行，共100道题；</p>
                <p>
                  （2）本项考试试题分为（单选题、多选题）两种题型，请注意审题，考试时限为90分钟；
                </p>
                <p>（3）考试成绩正确率达到60%则判定本模块评测通过；</p>
                <p>（4）考试时间到后，考生应停止答题，系统将自动收卷。</p>
                <p>
                  （5）正式考试每个月都会定期开放，下次正式考试开放时间为每月周六的9：00~21：00点，请考生注意时间安排。
                </p>
              </div>
              <el-button
                class="right-button testbtn"
                type="danger"
                @click="isPay('https://ks.netban.cn/entrance/715', 715)"
                >开始正式考试</el-button
              >
            </el-col>
          </el-row>
          <p class="cont">
            友情提示：
            <br />（1）正式考试开始时，系统会进行身份信息认证，学员的身份信息将和考取的网培师证书绑定，因此请正确填写您的身份证信息，信息一经确认将无法修改，因身份信息错误造成的后果，本平台不承担该责任；
            <br />（2）网培师智能监考平台在考试过程中会通过远程视频监控不定时地进行身份信息识别；
            <br />（3）考生考试期间请保持面部一直处于摄像头有效区域，如考试期间被抓拍到离开摄像头有效区域，则本次考试视为无效！
            <br />（4）考生在参加评测期间应该遵守考试纪律，独立完成评测内容；考试时不得向他人交流，也不能通过搜索引擎、书本等工具查阅相关内容；
            <br />（5）违反考试规定者一经发现，则作废本次所有考试并视为本次所有考试不及格！
          </p>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="教学数字化能力评测" name="fourth">
        <el-card>
          <h1 class="text-center">教学数字化能力评测</h1>
          <!-- <h2 class="text-center subhead">第1部分：考试部分</h2> -->
          <el-row :gutter="30">
            <el-col :span="12" class="text-left-explain">
              <!-- <img src="../assets/images/exam/img2.png" alt srcset /> -->
              <div class="paperBrief">
                <h4>模拟练习说明：</h4>
                <p>（1）付费完成后，系统即为学员开通模拟练习及正式考试权限；</p>
                <p>
                  （2）通过模拟练习，结合评测大纲，用户可以进行教学数字化能力相关知识的学习；
                </p>
                <p>
                  （3）模拟练习为三个模块的综合练习，试题将包含金山WPS演示及Microsoft
                  PowerPoint 软件试题、Adobe Photoshop及视频编辑软件（Premiere
                  Pro、Camtasia
                  Studio和会声会影三选一），模拟练习为90分钟、可重复参加练习；
                </p>
                <p>（4）模拟练习不记录成绩。</p>
              </div>
              <!-- <el-button
                type="danger"
                @click="toExam('https://ks.netban.cn/entrance/432',432)"
                >开始模拟练习</el-button
              > -->
              <el-button
                class="btn"
                type="danger"
                @click="isPay(type, null, 'simulation')"
                >开始模拟练习</el-button
              >
            </el-col>
            <el-col :span="12" class="text-left-explain">
              <!-- <img src="../assets/images/exam/img1.png" alt srcset /> -->
              <div class="paperBrief paperBrief-right">
                <h4>正式考试说明：</h4>
                <p>（1）本项评测要求考生采用在线答题的方式进行，共100道题；</p>
                <p>
                  （2）本项考试试题只有单选题型，请注意审题，考试时限为90分钟；
                </p>
                <p>
                  （3）试题分为60道金山WPS演示及Microsoft PowerPoint
                  软件试题、20道Adobe Photoshop试题和20道视频编辑相关试题；
                </p>
                <p>
                  （4）考试成绩正确率达到60%则判定本模块评测通过;
                </p>
                <p>（5）考试时间到后，考生应停止答题，系统将自动收卷;</p>
                <p>
                  （6）正式考试每个月都会定期开放，下次正式考试开放时间为每月周六的9：00~21：00点，请考生注意时间安排。
                </p>
              </div>
              <!-- <el-button class="right-button"
                type="danger"
                @click="toExam('https://ks.netban.cn/entrance/628',628)"
                >开始正式考试</el-button
              > -->
              <el-button
                class="btn btn-left-margin right-button"
                type="danger"
                @click="isPay(type, null, 'examine')"
                >开始正式考试</el-button
              >
            </el-col>
          </el-row>
          <h2 class="text-center subhead">第2部分：个人教学视频上传</h2>
          <img src="../assets/images/exam/img2.png" alt srcset />
          <div class="text-left">
            <el-row :gutter="20">
              <el-col :span="12" :offset="6">
                <div class="paperBrief paperBrief-right uploade">
                  <h4>上传视频作品要求：</h4>
                  <p>
                    （1）本项需用户在考试开放的72小时内上传一部本人制作的教学视频课件，学科不限；
                  </p>
                  <p>
                    （2）视频课件时长15分钟到30分钟，视频中本人出镜授课画面不少于3分钟；
                  </p>
                  <p>
                    （3）视频格式为MP4格式，分辨率不少于720P，上传的视频文件大小不高于200M；
                  </p>
                  <p>（4）请保证整段视频画面清晰，流畅，无画面障碍遮盖；</p>
                  <p>
                    （5）上传视频课件时请仔细选择包含：课程名称、课程简介（100
                    ~500字）、分类标签 等信息。
                  </p>
                  <p>
                    （6）在考试结束将会有工作人员审核您的视频课件！视频课件的审核结果将影响您的证书，请认真对待！
                  </p>
                  <br />
                  <br />
                  <el-button
                    type="danger"
                    @click="showUpload = true"
                    class="uploading"
                    style="margin-bottom:30px;"
                    >上传个人作品</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
          <p class="cont">
            友情提示：
            <br />（1）正式考试开始时，系统会进行身份信息认证，学员的身份信息将和考取的网培师证书绑定，因此请正确填写您的身份证信息，信息一经确认将无法修改，因身份信息错误造成的后果，本平台不承担该责任；
            <br />（2）网培师智能监考平台在考试过程中会通过远程视频监控不定时地进行身份信息识别；
            <br />（3）考生考试期间请保持面部一直处于摄像头有效区域，如考试期间被抓拍到离开摄像头有效区域，则本次考试视为无效！
            <br />（4）考生在参加评测期间应该遵守考试纪律，独立完成评测内容；考试时不得向他人交流，也不能通过搜索引擎、书本等工具查阅相关内容；
            <br />（5）违反考试规定者一经发现，则作废本次所有考试并视为本次所有考试不及格！
          </p>
        </el-card>
      </el-tab-pane>
    </el-tabs>
    <!-- 支付弹框 -->
    <el-dialog
      title="支付"
      :visible.sync="dialogVisible"
      width="500px"
      v-loading="loading"
      :close-on-click-modal="false"
    >
      <p>考试费支付：{{ this.$price }}</p>
      <br />
      <p>
        付费内容：教学综合能力评测、网培政策规范评测，教学数字化能力评测三个模块评测费、视频审阅费、教学练习（模考）费、证书费。
      </p>
      <el-input
        placeholder="请输入兑换码"
        class="redeem"
        v-model="redeemData"
      ></el-input>
      <el-button type="danger" @click="toRedeem" class="toRedeem"
        >使用兑换码</el-button
      >
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false" class="cancel">取 消</el-button> -->
        <el-button type="danger" @click="toPay">去支付</el-button>
      </span>
    </el-dialog>
    <!-- 上传作品弹框 -->
    <el-dialog
      :visible.sync="showUpload"
      width="400px"
      :close-on-click-modal="false"
    >
      <uploadWork :type="userCenUrlType" @getlist="getlist"></uploadWork>
    </el-dialog>
    <!-- 认证弹框 -->
    <el-dialog :visible.sync="showInfo" width="400px" v-loading="loading">
      <p>
        请确认您的身份信息，一旦认证成功将不能再次进行修改。如因身份信息有误导致无法考试、获取证书，本平台将不会退回费用。
      </p>
      <div class="flex-col-center flex-row-between">
        <span>姓名：</span><el-input v-model="form.name"></el-input>
      </div>
      <div class="flex-col-center flex-row-between">
        <span>身份证号：</span><el-input v-model="form.idcard"></el-input>
      </div>
      <div class="text-center">
        <el-button type="danger" size="small" @click="toRZ">认证</el-button>
      </div>
    </el-dialog>
    <!-- 考试项选择弹框 -->
    <el-dialog
      title="试题选择"
      :visible.sync="examSelect"
      width="450px"
      v-loading="loading"
      center
    >
      <div class="examselect">
        <p>1.请选择一个您最擅长的视频编辑软件；</p>
        <p>2.您的选择将决定评测中视频剪辑部分试题，请酌情选择；</p>
        <p>3.<span>选择后将无法更改其他视频编辑软件，请慎重！</span></p>
        <el-radio-group v-model="radio" style="padding-left:10px;">
          <el-radio
            v-for="item in radioOptions"
            :key="item.id"
            :label="item.id"
            >{{ item.title }}</el-radio
          >
          <!-- <el-radio :label="2">Camtasia Studio</el-radio>
           <el-radio :label="3">会声会影</el-radio> -->
        </el-radio-group>
        <div class="text-center">
          <el-button type="primary" @click="sure">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 资料查看下载弹框 -->
    <el-dialog :visible.sync="pdfShow" width="650px">
      <div class="toPdf">
        <h2 class="text-center">政策法规资料查看与下载</h2>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E5%AE%AA%E6%B3%95%20_%20%E6%BB%9A%E5%8A%A8%E6%96%B0%E9%97%BB%20_%E4%B8%AD%E5%9B%BD%E6%94%BF%E5%BA%9C%E7%BD%91.pdf"
            target="_blank"
            >1. 中华人民共和国宪法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E7%94%B5%E4%BF%A1%E6%9D%A1%E4%BE%8B_2016%C2%B72%E3%80%80%E5%A2%9E%E5%88%8A_%E4%B8%AD%E5%9B%BD%E6%94%BF%E5%BA%9C%E7%BD%91.pdf"
            target="_blank"
            >2. 中华人民共和国电信条例</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E4%BF%9D%E5%AE%88%E5%9B%BD%E5%AE%B6%E7%A7%98%E5%AF%86%E6%B3%95%EF%BC%88%E4%B8%BB%E5%B8%AD%E4%BB%A4%E7%AC%AC%E4%BA%8C%E5%8D%81%E5%85%AB%E5%8F%B7%EF%BC%89.pdf"
            target="_blank"
            >3. 中华人民共和国保守国家秘密法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%BA%92%E8%81%94%E7%BD%91%E7%9B%B4%E6%92%AD%E6%9C%8D%E5%8A%A1%E7%AE%A1%E7%90%86%E8%A7%84%E5%AE%9A-%E4%B8%AD%E5%85%B1%E4%B8%AD%E5%A4%AE%E7%BD%91%E7%BB%9C%E5%AE%89%E5%85%A8%E5%92%8C%E4%BF%A1%E6%81%AF%E5%8C%96%E5%A7%94%E5%91%98%E4%BC%9A%E5%8A%9E%E5%85%AC%E5%AE%A4.pdf"
            target="_blank"
            >4. 互联网直播服务管理规定</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E8%AE%A1%E7%AE%97%E6%9C%BA%E8%BD%AF%E4%BB%B6%E4%BF%9D%E6%8A%A4%E6%9D%A1%E4%BE%8B-%E4%B8%AD%E5%85%B1%E4%B8%AD%E5%A4%AE%E7%BD%91%E7%BB%9C%E5%AE%89%E5%85%A8%E5%92%8C%E4%BF%A1%E6%81%AF%E5%8C%96%E5%A7%94%E5%91%98%E4%BC%9A%E5%8A%9E%E5%85%AC%E5%AE%A4.pdf"
            target="_blank"
            >5. 计算机软件保护条例</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E8%AE%A1%E7%AE%97%E6%9C%BA%E4%BF%A1%E6%81%AF%E7%B3%BB%E7%BB%9F%E5%AE%89%E5%85%A8%E4%BF%9D%E6%8A%A4%E6%9D%A1%E4%BE%8B.pdf"
            target="_blank"
            >6. 中华人民共和国计算机信息系统安全保护条例</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E5%9B%BD%E5%8A%A1%E9%99%A2%E5%85%B3%E4%BA%8E%E4%BF%AE%E6%94%B9%E3%80%8A%E4%BF%A1%E6%81%AF%E7%BD%91%E7%BB%9C%E4%BC%A0%E6%92%AD%E6%9D%83%E4%BF%9D%E6%8A%A4%E6%9D%A1%E4%BE%8B%E3%80%8B%E7%9A%84%E5%86%B3%E5%AE%9A.pdf"
            target="_blank"
            >7. 信息网络传播权保护条例</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E8%91%97%E4%BD%9C%E6%9D%83%E6%B3%95.pdf"
            target="_blank"
            >8. 中华人民共和国著作权法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E7%BD%91%E7%BB%9C%E5%AE%89%E5%85%A8%E6%B3%95_%E6%BB%9A%E5%8A%A8%E6%96%B0%E9%97%BB_%E4%B8%AD%E5%9B%BD%E6%94%BF%E5%BA%9C%E7%BD%91.pdf"
            target="_blank"
            >9. 中华人民共和国网络安全法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%BA%92%E8%81%94%E7%BD%91%E8%91%97%E4%BD%9C%E6%9D%83%E8%A1%8C%E6%94%BF%E4%BF%9D%E6%8A%A4%E5%8A%9E%E6%B3%95.pdf"
            target="_blank"
            >10. 互联网著作权行政保护办法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E4%BE%B5%E6%9D%83%E8%B4%A3%E4%BB%BB%E6%B3%95_%E4%B8%AD%E5%9B%BD%E4%BA%BA%E5%A4%A7%E7%BD%91.pdf"
            target="_blank"
            >11. 中华人民共和国侵权责任法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E5%9B%BD%E5%8A%A1%E9%99%A2%E4%BB%A4%EF%BC%88%E7%AC%AC300%E5%8F%B7%EF%BC%89%E9%9B%86%E6%88%90%E7%94%B5%E8%B7%AF%E5%B8%83%E5%9B%BE%E8%AE%BE%E8%AE%A1%E4%BF%9D%E6%8A%A4%E6%9D%A1%E4%BE%8B_2001%E5%B9%B4%E7%AC%AC17%E5%8F%B7%E5%9B%BD%E5%8A%A1%E9%99%A2%E5%85%AC%E6%8A%A5_%E4%B8%AD%E5%9B%BD%E6%94%BF%E5%BA%9C%E7%BD%91.pdf"
            target="_blank"
            >12. 集成电路布图设计保护条例</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E5%90%88%E5%90%8C%E6%B3%95_%E4%B8%AD%E5%9B%BD%E4%BA%BA%E5%A4%A7%E7%BD%91.pdf"
            target="_blank"
            >13. 中华人民共和国合同法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E7%94%B5%E5%AD%90%E7%AD%BE%E5%90%8D%E6%B3%95%EF%BC%88%E4%B8%BB%E5%B8%AD%E4%BB%A4%E7%AC%AC%E5%8D%81%E5%85%AB%E5%8F%B7%EF%BC%89.pdf"
            target="_blank"
            >14. 中华人民共和国电子签名法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E4%B9%89%E5%8A%A1%E6%95%99%E8%82%B2%E6%B3%95%EF%BC%88%E4%B8%BB%E5%B8%AD%E4%BB%A4%E7%AC%AC%E4%BA%94%E5%8D%81%E4%BA%8C%E5%8F%B7%EF%BC%89_%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E4%B8%AD%E5%A4%AE%E4%BA%BA%E6%B0%91%E6%94%BF%E5%BA%9C%E9%97%A8%E6%88%B7%E7%BD%91%E7%AB%99.pdf"
            target="_blank"
            >15. 中华人民共和国义务教育法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E6%95%99%E8%82%B2%E6%B3%95%20-%20%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E6%95%99%E8%82%B2%E9%83%A8%E6%94%BF%E5%BA%9C%E9%97%A8%E6%88%B7%E7%BD%91%E7%AB%99.pdf"
            target="_blank"
            >16. 中华人民共和国教育法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E6%9C%AA%E6%88%90%E5%B9%B4%E4%BA%BA%E4%BF%9D%E6%8A%A4%E6%B3%95_%E4%B8%AD%E5%9B%BD%E4%BA%BA%E5%A4%A7%E7%BD%91.pdf"
            target="_blank"
            >17. 中华人民共和国未成年人保护法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E9%A2%84%E9%98%B2%E6%9C%AA%E6%88%90%E5%B9%B4%E4%BA%BA%E7%8A%AF%E7%BD%AA%E6%B3%95_%E4%B8%AD%E5%9B%BD%E4%BA%BA%E5%A4%A7%E7%BD%91.pdf"
            target="_blank"
            >18. 中华人民共和国预防未成年人犯罪法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E4%BF%A1%E6%81%AF%E4%BA%A7%E4%B8%9A%E9%83%A8%E4%BB%A4%EF%BC%88%E7%AC%AC3%E5%8F%B7%EF%BC%89%E4%BA%92%E8%81%94%E7%BD%91%E7%94%B5%E5%AD%90%E5%85%AC%E5%91%8A%E6%9C%8D%E5%8A%A1%E7%AE%A1%E7%90%86%E8%A7%84%E5%AE%9A_2001%E5%B9%B4%E7%AC%AC2%E5%8F%B7%E5%9B%BD%E5%8A%A1%E9%99%A2%E5%85%AC%E6%8A%A5_%E4%B8%AD%E5%9B%BD%E6%94%BF%E5%BA%9C%E7%BD%91.pdf"
            target="_blank"
            >19. 互联网电子公告服务管理规定</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%BA%92%E8%81%94%E7%BD%91%E4%BF%A1%E6%81%AF%E6%9C%8D%E5%8A%A1%E7%AE%A1%E7%90%86%E5%8A%9E%E6%B3%95_2011%C2%B71%E3%80%80%E5%A2%9E%E5%88%8A_%E4%B8%AD%E5%9B%BD%E6%94%BF%E5%BA%9C%E7%BD%91.pdf"
            target="_blank"
            >20. 互联网信息服务管理办法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E6%B2%BB%E5%AE%89%E7%AE%A1%E7%90%86%E5%A4%84%E7%BD%9A%E6%B3%95_%E4%B8%AD%E5%9B%BD%E4%BA%BA%E5%A4%A7%E7%BD%91.pdf"
            target="_blank"
            >21. 中华人民共和国治安管理处罚法</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E5%9B%BD%E5%AE%B6%E4%BA%92%E8%81%94%E7%BD%91%E4%BF%A1%E6%81%AF%E5%8A%9E%E5%85%AC%E5%AE%A4%E4%BB%A4%EF%BC%88%E7%AC%AC1%E5%8F%B7%EF%BC%89%E4%BA%92%E8%81%94%E7%BD%91%E6%96%B0%E9%97%BB%E4%BF%A1%E6%81%AF%E6%9C%8D%E5%8A%A1%E7%AE%A1%E7%90%86%E8%A7%84%E5%AE%9A_2017%E5%B9%B4%E7%AC%AC29%E5%8F%B7%E5%9B%BD%E5%8A%A1%E9%99%A2%E5%85%AC%E6%8A%A5_%E4%B8%AD%E5%9B%BD%E6%94%BF%E5%BA%9C%E7%BD%91.pdf"
            target="_blank"
            >22. 互联网新闻信息服务管理规定</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E5%85%A8%E5%9B%BD%E4%BA%BA%E6%B0%91%E4%BB%A3%E8%A1%A8%E5%A4%A7%E4%BC%9A%E5%B8%B8%E5%8A%A1%E5%A7%94%E5%91%98%E4%BC%9A%E5%85%B3%E4%BA%8E%E5%8A%A0%E5%BC%BA%E7%BD%91%E7%BB%9C%E4%BF%A1%E6%81%AF%E4%BF%9D%E6%8A%A4%E7%9A%84%E5%86%B3%E5%AE%9A_%E4%B8%AD%E5%9B%BD%E4%BA%BA%E5%A4%A7%E7%BD%91.pdf"
            target="_blank"
            >23. 全国人民代表大会常务委员会关于加强网络信息保护的决定</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E5%9B%BD%E5%8A%A1%E9%99%A2%E5%85%B3%E4%BA%8E%E6%8E%88%E6%9D%83%E5%9B%BD%E5%AE%B6%E4%BA%92%E8%81%94%E7%BD%91%E4%BF%A1%E6%81%AF%E5%8A%9E%E5%85%AC%E5%AE%A4%E8%B4%9F%E8%B4%A3%E4%BA%92%E8%81%94%E7%BD%91%E4%BF%A1%E6%81%AF%E5%86%85%E5%AE%B9%E7%AE%A1%E7%90%86%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5_%E6%94%BF%E5%BA%9C%E4%BF%A1%E6%81%AF%E5%85%AC%E5%BC%80%E4%B8%93%E6%A0%8F.pdf"
            target="_blank"
            >24.
            国务院关于授权国家互联网信息办公室负责互联网信息内容管理工作的通知</a
          >
        </div>
        <div>
          <a
            href="http://resource.wangpeishi.org.cn/pdf/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E6%95%99%E5%B8%88%E6%B3%95%20-%20%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E6%95%99%E8%82%B2%E9%83%A8%E6%94%BF%E5%BA%9C%E9%97%A8%E6%88%B7%E7%BD%91%E7%AB%99.pdf"
            target="_blank"
            >25. 中华人民共和国教师法</a
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import UploadWork from '../components/uploadWork'
let _this
export default {
  name: 'Exam',
  components: { UploadWork },
  data() {
    return {
      pdfShow: false,
      toForm: {},
      userCenUrlType: '2',
      current: 'second',
      dialogVisible: false,
      showUpload: false,
      form: {},
      order_number: '',
      pay: false, //是否支付过
      showInfo: false,
      obj: {},
      loading: false,
      cookieId: '',
      butInfo: {
        url: '',
        id: '',
      },
      redeemData: '',
      examSelect: false,
      radioOptions: [],
      type: 1,
      radio: '',
    }
  },
  created() {
    _this = this
    this.form = {
      name: this.$store.state.wps_user.name,
      idcard: this.$store.state.wps_user.idcard,
    }
    this.obj = this.$store.state.wps_user
    this.init()
    // _this.setNetbanCookie();
  },
  mounted() {
    switch (this.$route.params.id) {
      case '1':
        this.current = 'second'
        break
      case '2':
        this.current = 'third'
        break
      case '3':
        this.current = 'fourth'
        break
    }
  },
  methods: {
    init() {},
    // handleChange(cur) {
    //   if (cur.index == 0) {
    //     this.$router.push({ name: "Home" });
    //   }
    // },
    papers(istrue) {
      this.$http.get('http://api.wangpeishi.org.cn/api/papers').then(res => {
        if (res.data.code == 200) {
          if (istrue == 'examine') {
            _this.setNetbanCookie(res.data.data)
          } else if (istrue == 'simulation') {
            _this.setNetbanCookie(res.data.simulation)
          }
        } else if (res.data.code == 201) {
          if (istrue == 'examine') {
            this.radioOptions = res.data.data
          } else if (istrue == 'simulation') {
            this.radioOptions = res.data.simulation
          }
          this.examSelect = true
        }
      })
    },
    isPay(url, id, istrue) {
      //判断是否支付
      this.toForm.url = url
      this.toForm.id = id
      this.toForm.istrue = istrue
      this.cookieId = id
      _this.$http
        .post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
        .then(data => {
          if (data.data.code == 403) {
            _this.$message.error(data.data.msg)
            return
          }
          if (data.data.code == 200) {
            _this.dialogVisible = true
          } else {
            if (!_this.obj.is_verfy) {
              _this.showInfo = true
            } else {
              if (istrue) {
                _this.papers(istrue)
              } else {
                _this.setNetbanCookie()
              }
            }
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    toPay() {
      //去支付
      this.toExam()
    },
    toExam() {
      // this.toForm.url = url
      // this.toForm.id = id
      // this.toForm.istrue = istrue
      this.cookieId = this.toForm.id
      let subsystem_url = window.location.href
      _this.$http
        .post(process.env.VUE_APP_URL + 'order', {
          good_id: 1,
          subsystem_url: subsystem_url,
        })
        .then(data => {
          if (data.data.code == 200) {
            //未付款
            _this.order_number = data.data.data
            window.location.href =
              ' http://pay.netban.cn/pay/' + this.order_number //跳转至支付页面
            // _this.dialogVisible = true;
          } else if (data.data.code == 201) {
            //已付款
            if (!_this.obj.is_verfy) {
              _this.showInfo = true
            } else {
              if (this.toForm.istrue) {
                _this.papers(this.toForm.istrue)
              } else {
                _this.setNetbanCookie()
              }
            }
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    toRZ() {
      this.$confirm('认证后将不能修改姓名和身份证号, 是否继续?', '认证', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true
          _this.$http
            .post(process.env.VUE_APP_URL + 'verify', this.form)
            .then(data => {
              _this.loading = false
              if (data.data.code == 403) {
                _this.$message.error(data.data.msg)
                return
              }
              _this.showInfo = false
              _this.obj.name = _this.form.name
              _this.obj.idcard = _this.form.idcard
              _this.obj.is_verfy = 1
              _this.$store.commit('setUser', _this.obj)
              _this.$message.success('认证成功！去参与测评吧')
              if (_this.toForm.istrue) {
                _this.papers(_this.toForm.istrue)
              } else {
                _this.setNetbanCookie()
              }
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              _this.loading = false
              console.log(err)
            })
        })
        .catch(() => {
          _this.$message({
            type: 'info',
            message: '已取消认证',
          })
        })
    },

    toRedeem() {
      //兑换码
      _this.loading = true
      _this.$http
        .post(process.env.VUE_APP_URL + 'exchange', {
          good_id: 1,
          numbers: this.redeemData,
        })
        .then(data => {
          _this.loading = false
          if (data.data.code == 403) {
            _this.$message.error(data.data.msg)
            return
          }
          if (data.data.code == 500) {
            _this.$message.error(data.data.msg)
            return
          }
          if (!_this.obj.is_verfy) {
            _this.showInfo = true
          } else {
            if (_this.toForm.istrue) {
              _this.papers(_this.toForm.istrue)
            } else {
              _this.setNetbanCookie()
            }
          }
          _this.dialogVisible = false
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          _this.loading = false
          console.log(err)
        })
    },
    downloadShow() {
      this.pdfShow = true
    },
    sure() {
      let postData = {
        papers: this.radio,
      }
      _this.$http
        .post(process.env.VUE_APP_URL + 'papersUpdate', postData)
        .then(data => {
          if (data.data.code == 200) {
            this.setNetbanCookie(this.radio)
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    getlist(data) {
      if (data == 200) {
        this.showUpload = false
      }
    },
    setNetbanCookie(id) {
      console.log(id)
      if (id) {
        console.log(id)
        window.location.href =
          'http://netban.cn/api/user/synwp/' +
          this.$store.state.wps_user.mobile +
          '?eid=' +
          id
      } else {
        window.location.href =
          'http://netban.cn/api/user/synwp/' +
          this.$store.state.wps_user.mobile +
          '?eid=' +
          this.cookieId
      }
    },
    go_url() {
      // setTimeout(function() {
      //   window.location.href = url;
      // },1000);
    },
  },
}
</script>
<style lang="less" scoped>
.paperBrief {
  padding: 50px 50px 50px 0px;
  height: 180px;
}
.paperBrief-right {
  padding: 50px 0px 50px 50px;
}
.right-button {
  margin: 25px 0 20px 50px !important;
}
.btn {
  margin: 140px 0 20px 0px !important;
}
.testbtn {
  margin-top: 85px !important;
}
.btn-left-margin {
  margin-left: 50px !important;
}
.paperBrief p {
  margin-top: 12px;
}
.uploade {
  height: 350px;
}
.subhead {
  margin-top: 10px;
  margin-bottom: -10px;
}
.exam {
  // padding: 20px 0;
  font-size: 16px;
  color: #333;
  /deep/.el-tabs__header {
    // background: #ce9a6c;
    background: #960f23;
    padding: 5px 15%;
    .el-tabs__item {
      color: #fff;
      font-size: 18px;
    }
    .el-tabs__item.is-active {
      color: #fff;
    }
    .el-tabs__active-bar {
      background-color: #fff;
    }
    .el-tabs__nav-wrap::after {
      background: none;
    }
  }
  .el-card {
    width: 1280px;
    margin: 0 auto;
    // padding: 45px 115px;
    padding: 10px 115px;
    box-sizing: border-box;
    // .headline{
    //   // margin-bottom: 87px;
    // }
    .text-left-explain {
      height: 500px;
    }
    img {
      width: 400px;
      display: block;
      margin: 0 auto;
    }
    /deep/.el-button--danger {
      background: #e10133;
      width: 220px;
      color: #fff;
      margin: 20px 0;
    }
    h1 {
      font-size: 30px;
      color: #000005;
    }
    h2 {
      font-size: 24px;
      color: #000005;
      padding: 15px 0;
    }
    .cont {
      line-height: 28px;
      padding: 20px;
      background-color: #eee;
      // margin-top: 120px;
      margin-top: 10px;
    }
  }
  .el-dialog {
    .el-input {
      width: 270px;
      margin: 10px 0;
    }
    .el-button--danger {
      background: #e10133;
      width: 100px;
      margin-top: 50px;
    }
  }
  .uploading {
    margin-bottom: 100px;
  }
  .redeem {
    padding: 10px 10px 0 0;
  }
  .toRedeem {
    width: 120px !important;
    margin-left: 14px;
  }
  .cancel {
    margin-right: 225px;
  }
  .examselect {
    p {
      margin: 12px;
      font-size: 15px;
      span {
        color: red;
      }
    }
    div {
      padding-top: 20px;
      .el-button {
        width: 110px;
      }
    }
  }
  .el-radio {
    margin: 6px;
  }
  .download {
    padding-top: 10px;
    font-size: 15px;
    .el-link {
      color: red;
      font-size: 16px;
    }
  }
  .toPdf {
    h2 {
      margin-bottom: 25px;
      font-size: 20px;
      color: #960f23;
    }
    div {
      margin: 5px;
      font-size: 16px;
    }
  }
}
</style>
